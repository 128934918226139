import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
    theme: {
        primary: '#ee44aa',
        secundary: '#424242',
        accent: '#82b1ff',
        error: '#FF5252',
        info: '#2196f3',
        success: '#4caf50',
        warning: 'FFc107'
    },
    customPorperties: true,
    iconfont:'md'
});

export default new Vuetify({
});
