<template>
  <div class="home">
    <v-app-bar app class="appBar"  style="right: 0px !important;">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-xl-none d-sm-none">
          <v-img :src="Logo" class="nav_icon"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
        >
        <template v-slot:activator="{ on, attrs }">
            <button
            v-bind="attrs"
            v-on="on"
            class="appBar__account__button"
            @click="drawerInfoUser = !drawerInfoUser"
            >
            <img :src="account">
            <span class="appBar__account__button--text">
                Mi cuenta
            </span>
              <ArrowIcon class="ml-2" style="transform: rotate(180deg)" />
            </button>
        </template>
    </v-menu>
    </v-app-bar>


    <!-- menu lateral derecho-->
      <v-card
        width="300"
        class="mx-auto"
      >
        <v-navigation-drawer style="margin-top:65px;margin-right:20px;border-radius:10px"
          right 
          class="drawer"
          :clipped="clipped"
          v-model="drawerInfoUser"
          enable-resize-watcher
          app
          width="300"
          height="700"
        >

        <v-list class="drawer__body">
            <v-list-item>
              <v-avatar size="100">
                <img src="@/assets/img/img-perfil.svg">
              </v-avatar>
              <CameraIcon style="margin-left:20px;"/>
            </v-list-item>

            
            <v-list-item-title class="text-h6" style="margin-left:10px;margin-top:10px;">
                {{name}} {{lastname}}
            </v-list-item-title>
            <v-list-item-subtitle style="margin-left:10px;">
                {{email}}
            </v-list-item-subtitle>
          </v-list>

          <v-list class="drawer__body">
            <v-list-item
              v-for="item in items2"
              :key="item.title"
              link
              class="drawer__body__item"
              :to="item.route"
              @mouseover="item.hover = true"
              @mouseleave="item.hover = false"
              :active-class="'item-active-'+item.id"
            >
              <v-list-item-icon>
                <!--<v-icon class="drawer__body__item--text">{{ item.icon }}</v-icon>-->
                <component v-bind:is="item.icon" :color="item.hover ? '#000000': item.route == currentRouteName ? '#FFFFFF' : '#808191' "></component>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="drawer__body__item--text">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>

          <v-divider class="drawer__divider"></v-divider>

          <v-list class="drawer__body">
            <v-list-item
              v-for="item in items3"
              :key="item.title"
              link
              class="drawer__body__item"
              :to="item.route"
              @click="handle_function_call(item.method)"
              @mouseover="item.hover = true"
              @mouseleave="item.hover = false"
              :active-class="'item-active-'+item.id"
            >
              <v-list-item-icon>
                <!--<v-icon class="drawer__body__item--text">{{ item.icon }}</v-icon>-->
                <component v-bind:is="item.icon" :color="item.hover ? '#000000': item.route == currentRouteName ? '#FFFFFF' : '#808191' "></component>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="drawer__body__item--text">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>

          
           <v-list class="drawer__body">
                <button  @click="logout()"
                  
                  class="dropdown_button float-right py-3 pr-3"
                  style="float: none !important; width:100%;"
                  
                  v-ripple="{ center: true }"
                >
                <LogoutIcon style="margin-left:-60px;margin-right:10px;"/>
                  <span 
                    class="dropdown_button--text"  
                  >
                    Cerrar sesión
                  </span>

                </button>
          </v-list>

        </v-navigation-drawer>
      </v-card>


 <!-- menu lateral izquierdo -->
     <v-card
        width="300"
        class="mx-auto"
      >
        <v-navigation-drawer
          class="drawer"
          :clipped="clipped"
          v-model="drawer"
          enable-resize-watcher
          app
          width="300"
        >
          <v-list-item class="drawer__header">
            <v-list-item-content>
              <v-img :src="Logo"></v-img>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="drawer__divider"></v-divider>

          <v-list class="drawer__body">
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              class="drawer__body__item"
              :to="item.route"
              @click="drawer=false"
              @mouseover="item.hover = true"
              @mouseleave="item.hover = false"
              :active-class="'item-active-'+item.id"
            >
              <v-list-item-icon>
                <!--<v-icon class="drawer__body__item--text">{{ item.icon }}</v-icon>-->
                <component v-bind:is="item.icon" :color="item.hover ? '#000000': item.route == currentRouteName ? '#FFFFFF' : '#808191' "></component>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="drawer__body__item--text">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-navigation-drawer>
      </v-card>

      
      <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 lighten-2" style="background-color:#1476FB;height:50px;">
          <div class="button_header" @click="closeModal()" >
            <CloseIcon></CloseIcon>
          </div>
        </v-card-title>

        <v-card-text>

          <v-card-title>
            <p  class="modal_title">Cerrar sesión en todos los dispositivos</p>
          </v-card-title>

          <div style="text-align:center;">Por seguridad, ingresa tu contraseña para cerrar la sesión de tu cuenta en todos los dispositivos.</div>

          <v-text-field
            outlined
            label="Contraseña actual"
            style="border-radius: 10px !important;margin-top:20px;"
            :error-messages="errors"
            v-model="password"
            required
            :append-icon="value ? 'visibility' : 'visibility_off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
          ></v-text-field>

        <v-row class="mb-6" no-gutters>

          <v-col md="6" >           
           <v-btn depressed color="error" class="button_close" @click="logoutAll()">
            Cerrar todas las sesiones
           </v-btn>
          </v-col>

          <v-col md="6">
              
            <v-btn depressed color="#D6D7E5" class="button_close" @click="closeModal()">
              Cancelar
            </v-btn>
              
          </v-col>
        </v-row>

        </v-card-text>
    
      </v-card>
    </v-dialog>

       <!-- <v-dialog
          v-model="dialog"
          hide-overlay
          persistent
          width="300"
        >
      <v-card
        color="primary"
        dark
      >
        <v-card-text style="text-align:center;">
          <h6 style="margin-bottom:15px">Cerrando las sesiones en todos los dispositivos</h6>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
          <h6 style="margin-top:10px">Cargando...</h6>
        </v-card-text>
      </v-card>
    </v-dialog> -->


    </div>
</template>
<script>

import Logo from '../../assets/img/logo.png'
import account from '@/assets/img/icons/user.png'

export default {
    data: () => ({
      selectedItem: 0,
      drawer: false,
      drawerInfoUser: false,
      showMenu: false,
      Logo: Logo,
      name:'',
      email:'',
      lastname:'',
      userId:'',
      dialog: false,
      clipped: false,
      password:'',
      value:'',
      errors:'',
      items: [
          { id: 1, title: 'Ofertas', icon: 'OffersIcon', route: '/offers', hover: false, color: '#F44336' },
          { id: 2, title: 'Tus beneficios', icon: 'RewardsIcon', route: '/benefits', hover: false, color: '#E33C76' },
          { id: 3, title: 'Locaciones', icon: 'LocationIcon', route: '/locations', hover: false, color: '#51C97B' },
          { id: 4, title: 'Noticias', icon: 'NewsIcon', route: '/news', hover: false },
          { id: 5, title: 'Eventos', icon: 'EventsIcon', route: '/events', hover: false },
          { id: 6, title: 'Notificaciones', icon: 'MessageIcon', route: '/notifications', hover: false },
          // { id: 7, title: 'Trámites internos', icon: 'TramitesInternosIcon', route: '/procedures', hover: false },
          // { id: 8, title: 'Empresas', icon: 'ClientConnectionsIcon', route: '/companys', hover: false },
      ],
      items2: [
          { id: 1, title: 'Editar Perfil', icon: 'EditProfileIcon', route: '', hover: false, color: '#F44336' },

      ],
      items3: [
          { id: 1, title: 'Cambiar Contraseña', icon: 'PasswordIcon', route: '', hover: false, color: '#F44336' },
          { id: 2, title: 'Pin de respaldo', icon: 'PinIcon', route: '', hover: false, color: '#E33C76' },
          { id: 3, title: 'Ver términos y condiciones', icon: 'TermsIcon', route: '', hover: false, color: '#51C97B' },
          { id: 4, title: 'Cerrar sesión en todos los dispositivos', icon: 'LogoutAllIcon', route: '', method:'showModal',hover: false, color: '#51C97B' },
          
      ],
      account: account,
      user_options: [
          { title: 'Mi perfil', route: '/offers' },
          { title: 'Otra opción', route: '/benefits' },
          { title: 'Cerrar sesión', route: '/locations' },
      ],
      //current_route: this.$router.currentRoute.name
    }),
    methods: {

        handle_function_call(method_name){

          console.log('data',method_name)
          this[method_name]()
        },
        
        closeModal(){
          this.dialog = false

        },

        showModal(){
          this.dialog = true
        },

        logout(){
          this.deleteSession('login', true)
          localStorage.removeItem('infoClient')
          localStorage.removeItem('emailClient')
          localStorage.removeItem('authenticationToken')
          localStorage.removeItem('userId')
        },

        logoutAll(){

          let vm = this
          if(vm.password){

              this.$http.post("close_all_sessions", {
                  data: this.encrypt({id:this.userId, password: this.password}).toString()
                }, {
                  headers: {
                    "Authorization": "Bearer " + localStorage.authenticationToken,
                    "X-Device-ID" : this.buildDeviceId(),
                    "Geolocation" : "lat: " + vm.getLatitude() + ", long: " + vm.getLongitude()
                  }  

              }).then(function(response){
                    if (response){
                        this.logout()
                    }
                }).catch(function (error) {
                    console.log('error',error.body.errors)
                    this.errors = error.body.errors
                }); 
              }else{
                this.errors = 'Por favor ingrese la contraseña'
              }
          }

 
    },
    computed: {
      currentRouteName() {
          return this.$route.path;
      }
  },

  created(){

    var infoClient = JSON.parse(localStorage.getItem("infoClient"))
    this.email = localStorage.getItem("emailClient")
    this.userId = localStorage.getItem("userId")

    this.name = infoClient.name;

    this.lastname = infoClient.last_name;


  }
}
</script>
<style>
  .item-active-1{
    background: #F44336 !important;
  }
  .item-active-2{
    background: #E33C76 !important;
  }
  .item-active-3{
    background: #51C97B !important;
  }
  .item-active-4{
    background: #5242CB !important;
  }
  .item-active-5{
    background: #17A277 !important;
  }
  .item-active-6{
    background: #1CBF20 !important;
  }
  .item-active-7{
    background: #24BCC3 !important;
  }
  .item-active-8{
    background: #6D1AC4 !important;
  }

.button_close{
  width: 95%;
  height: 50px !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  margin-left:5px;
}

.button_header{
  
  position: absolute;
  right: 0px;
  padding: 10px;

}

.modal_title{
  text-align:center;
  font-weight:bold;
  font-size:22px;
  margin-left: 10px;
}

</style>
