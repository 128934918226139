<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="12" viewBox="0 0 30 12">
  <g id="cambiar-contrasena" transform="translate(-22 -594)">
    <path id="Trazado_7372"  data-name="Trazado 7372" d="M7.071,22.628l-1.163.388V21.775A.733.733,0,0,0,5.132,21a.733.733,0,0,0-.775.775v1.241l-1.163-.388a.807.807,0,0,0-1.008.465A.747.747,0,0,0,2.651,24.1l1.163.388L3.039,25.5a.834.834,0,0,0,.155,1.085,1.1,1.1,0,0,0,.465.155.705.705,0,0,0,.62-.31l.775-1.008.853,1.008a.705.705,0,0,0,.62.31,1.1,1.1,0,0,0,.465-.155A.834.834,0,0,0,7.148,25.5l-.775-1.008L7.613,24.1a.776.776,0,0,0,.465-1.008A.845.845,0,0,0,7.071,22.628Z" transform="translate(23.632 576.131)" fill="#808191"/>
    <path id="Trazado_7373"  data-name="Trazado 7373" d="M7.071,22.628l-1.163.388V21.775A.733.733,0,0,0,5.132,21a.733.733,0,0,0-.775.775v1.241l-1.163-.388a.807.807,0,0,0-1.008.465A.747.747,0,0,0,2.651,24.1l1.163.388L3.039,25.5a.834.834,0,0,0,.155,1.085,1.1,1.1,0,0,0,.465.155.705.705,0,0,0,.62-.31l.775-1.008.853,1.008a.705.705,0,0,0,.62.31,1.1,1.1,0,0,0,.465-.155A.834.834,0,0,0,7.148,25.5l-.775-1.008L7.613,24.1a.776.776,0,0,0,.465-1.008A.845.845,0,0,0,7.071,22.628Z" transform="translate(31.868 576.131)" fill="#808191"/>
    <path id="Trazado_7374"  data-name="Trazado 7374" d="M7.071,22.628l-1.163.388V21.775A.733.733,0,0,0,5.132,21a.733.733,0,0,0-.775.775v1.241l-1.163-.388a.807.807,0,0,0-1.008.465A.747.747,0,0,0,2.651,24.1l1.163.388L3.039,25.5a.834.834,0,0,0,.155,1.085,1.1,1.1,0,0,0,.465.155.705.705,0,0,0,.62-.31l.775-1.008.853,1.008a.705.705,0,0,0,.62.31,1.1,1.1,0,0,0,.465-.155A.834.834,0,0,0,7.148,25.5l-.775-1.008L7.613,24.1a.776.776,0,0,0,.465-1.008A.845.845,0,0,0,7.071,22.628Z" transform="translate(40.104 576.131)" fill="#808191"/>
    <g id="Rectángulo_833" data-name="Rectángulo 833" transform="translate(22 594)" fill="none" stroke="#808191" stroke-width="1">
      <rect width="30" height="12" rx="6" stroke="none"/>
      <rect x="0.5" y="0.5" width="29" height="11" rx="5.5" fill="none"/>
    </g>
  </g>
</svg>

</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
