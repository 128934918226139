import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
// import { store } from '../store/index';

Vue.mixin({
  methods: {
    ...mapActions([
      'updateCurrentCompany',
      'updateLatitude',
      'updateLongitude',
      'updateDeviceId',
      'updateUserToken',
      'updateUserIdEncrypt',
      'updateUserId',
      'updateUserInfoId',
      'updateCategories',
      'updateCurrentCategory',
      'updateLocations',
      'updateNotifications',
      'updateBadgeNotifications',
      'updateIsMobile',
    ]),
    ...mapGetters([
      'getCurrentCompany',
      'getLatitude',
      'getLongitude',
      'getDeviceId',
      'getUserToken',
      'getUserIdEncrypt',
      'getUserId',
      'getUserInfoId',
      'getCategories',
      'getCurrentCategory',
      'getLocations',
      'getNotifications',
      'getBadgeNotifications',
      'getIsMobile',
    ]),
    encrypt(data) {
      var CryptoJS = require("crypto-js");
      var payload = "G7qeaR2Yb4DAgk92ZQHdjQ==|gfYvWJ0vVdX142/xpsGPW8Fsgc/dGeQPFtaazcUMjQo=|VF/bl6Ae5CVTtQTPCrmAgQ==".split("|");
      var iv = payload[0]
      // var hmac = payload[1], cipherText = payload[2];
      var passphraseDgst = CryptoJS.SHA256("ImAwesomeAndHappy").toString();
      var key = passphraseDgst;
      var encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Hex.parse(key),
        {iv:CryptoJS.enc.Base64.parse(iv)}
      );
      return encrypted
    },
    findPosition(position){
      console.log(position);
      this.updateLatitude(position.coords.latitude);
      this.updateLongitude(position.coords.longitude);
      console.log("lat: " + this.getLatitude().toString() + ", long: " + this.getLongitude().toString());
    },
    buildDeviceId(){
      var uuid
      if(localStorage.device_id != undefined &&
         localStorage.device_id != null &&
         localStorage.device_id != ''){
        uuid = localStorage.device_id
      } else {
        uuid = this.$uuid.v1()
        localStorage.device_id = uuid
      }
      this.updateDeviceId(uuid);
      return uuid
    },
    checkLoginUser(route, has_params = false, object){
      if(this.getUserToken() != '' && this.getUserToken() != undefined && this.getUserToken() != null){
        if(has_params){
          this.$router.push({name: route, params: object})
        } else {
          this.$router.push({name: route})
        }
      } else {
        this.show_error_modal(this.login_message, '¡Hola!', 'Ingresa Aquí', true)
      }
    },
    checkToken(token){
      this.updateUserToken(token);
      console.log("Nuevo Token!!!!!");
      console.log(this.getUserToken());
    },
    deleteSession(route, router){
      var vm = this;
      vm.updateUserToken('');
      vm.updateUserId('');
      localStorage.removeItem('infoClient');
      localStorage.removeItem('userId');
      localStorage.removeItem('emailClient');
      localStorage.removeItem('authenticationToken');
      // if(navigator.geolocation != null &&
      //    navigator.geolocation != undefined &&
      //    navigator.geolocation != ''){
      //   navigator.geolocation.getCurrentPosition(vm.findPosition);
      //   try {
      //     this.$http.delete('users/sign_out', null, {
      //       headers:{
      //         "X-Device-ID" : this.buildDeviceId(),
      //         "Authorization": "Bearer " + vm.getUserToken(),
      //         "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
      //       }
      //     }).then(function(response){
      //       this.clearNotifications()
      //       this.updateProfilePicture('./src/assets/images/backgrounds/menu_foto.svg')
      //     })
      //   } catch (e) {
      //     this.show_error_modal(e.message);
      //   }
      // }
      if(router){
        vm.$router.push({ name: route });
      }
    },
    show_error_modal(message, title, button){
      title = title || "Error";
      button = button || "Cerrar";
      var error_message = ""
      if (message != null && message != undefined){
        error_message = message
      }
      alert(error_message)
      console.log(button);
      // $("#errorsModal").find(".modal-title").text(title);
      // $("#errorsModal").find(".modal-body p").text(error_message);
      // $("#errorsModal").find(".modal-footer a").text(button);
      // if(login_image){
      //   $("#errorsModal").find(".error_modal__image").show();
      // }
      // $("#errorsModal").modal();
      // if(!cancel){
      //   $("#errorsModal").find(".modal_close").hide();
      // }
    },
    checkDevice(){
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        this.updateIsMobile(true)
      }else{
        this.updateIsMobile(false)
      }
      console.log("------------------> Check Device");
      console.log(this.getIsMobile());
    }
  }
})

// module.exports = store;
