<template>
    <div class="detail_offer__footer py-6">
        <v-row justify="end" class="mr-lg-3 mr-1 mr-sm-3">
            <a 
                href="#"
                class="detail_offer__btn__icon mr-3"
                v-ripple="{ center: true }"
                v-if="service === 'news'"
            >
                <span class="detail_offer__btn__icon--text" style="color:#808191">CATEGORÍA</span>
            </a>
            <a 
                href="#"
                class="detail_offer__btn__icon mr-3"
                v-ripple="{ center: true }"
            >
                <img :src="icons.shareSolid">
            </a>
            <a 
                href="#"
                class="detail_offer__btn__icon mr-3"
                v-ripple="{ center: true }"
            >
                <img :src="icons.favoriteSolid">
            </a>
            <button 
                class="detail_offer__btn px-8"
                :style="{backgroundColor: primaryColor}"
                style="color: #FFFFFF"
                v-ripple="{ center: true }"
                v-if="service === 'offers'"
            >
                <span class="px-lg-2">Canjear oferta</span> 
            </button>
            <button 
                class="detail_offer__btn px-8"
                :style="{backgroundColor: primaryColor}"
                style="color: #FFFFFF"
                v-ripple="{ center: true }"
                v-if="service === 'events'"
            >
                <span class="px-lg-2">Asistir al evento</span> 
            </button>
        </v-row>
    </div>
</template>
<script>
export default {
    props:{
        primaryColor: String,
        secundaryColor: String,
        service: String,
        icons: Object
    }
}
</script>