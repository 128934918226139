<template>
    <v-app>
    <Navigation/>
    <v-main>
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0 py-ms-0 detail_offer">
        <router-view></router-view>
        <div class="detail_offer_contain">
            <ItemDetailHead
                :icons="icons"
                :primaryColor="primaryColor"
                :secundaryColor="secundaryColor"
                :service="service"
             />
            <div class="detail_offer__body px-lg-8 px-5 py-md-8 py-4">
                <v-row class="detail_offer__contain__title">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pr-12">
                        <h3
                          class="detail_offer__body--title mb-8"
                          v-html="current_service.title">
                        </h3>

                        <!-- Información especifica de las locaciones -->
                        <div
                            v-if="service === 'locations'"
                        >
                            <span class="detail_offer__body--info">
                                <v-icon class="material-icons-outline">phone</v-icon>
                                <span class="date--text">{{current_service.phone}}</span>
                            </span>
                            <span class="detail_offer__body--info" v-if="current_service.email !='' && current_service.email != null">
                                <v-icon class="material-icons-outline">mail_outline</v-icon>
                                <span class="date--text">{{current_service.email}}</span>
                            </span>
                            <span class="detail_offer__body--info">
                                <v-icon class="material-icons-outline">location_on</v-icon>
                                <span class="date--text">{{current_service.address}}, {{current_service.city}}</span>
                            </span>
                        </div>

                        <div
                            class="detail_offer__body--date "
                            :class="service == 'events' ? 'pt-lg-4': 'py-lg-4 mb-3'"
                            v-else
                        >
                            <DateIcon
                                color="#1d8fcc"
                                height="17.5"
                                width="13.5"
                            />
                            <span class="date--text">{{service == 'news' ? 'Creado el' : 'Hasta el'}} {{current_service.expiration_date}}</span>
                        </div>

                        <div
                            class="detail_offer__body--date"
                            :class="service == 'events' ? 'pt-lg-2 pb-lg-4': ''"
                            v-if="service === 'events'"
                        >
                            <LocationIcon
                                color="#1d8fcc"
                                height="17.5"
                                width="13.5"
                            />
                            <span class="date--text">Ubicación del evento</span>
                        </div>

                        <v-divider class="col-12 pb-0"/>

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-md-10">
                        <v-img :src="current_service.img" class="detail_offer__body__img"></v-img>
                    </div>
                </v-row>
                <div class="py-4 px-sm-4 px-lg-0">
                    <p
                      class="detail_offer__body__description"
                      v-html="current_service.description">
                    </p>
                </div>

                <v-divider class="py-1 divider--hidden"/>

                <ItemDetailFoot
                    :icons="icons"
                    :primaryColor="primaryColor"
                    :secundaryColor="secundaryColor"
                    :service="service"
                />

            </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
    import Navigation from '@/components/shared/Navigation.vue'
    import ItemDetailHead from '@/components/DetailHead.vue'
    import ItemDetailFoot from '@/components/DetailFoot.vue'

    import favorite from '@/assets/img/icons/favorito.png'
    import favoriteSolid from '@/assets/img/icons/favorito-solid.png'
    import share from '@/assets/img/icons/share.png'
    import shareSolid from '@/assets/img/icons/share-solid.png'
    import detailOffer from '@/assets/img/detailOfert.jpg'
    import leftArrow from '@/assets/img/icons/icono-atras.svg'

  export default {
    props: {
        colors: {
            type: Object,
            default: () => {}
        },
    },
    components: {
        Navigation,
        ItemDetailHead,
        ItemDetailFoot
    },
    data: () => ({
        Nav: 'Aplication',
        primaryColor: '#F44336',
        secundaryColor: '#f57970',
        icons: {
            favorite: favorite,
            share: share,
            favoriteSolid: favoriteSolid,
            shareSolid: shareSolid,
            leftArrow: leftArrow
        },
        detailOffer: detailOffer,
        service: '',
        current_service: {
          title:'',
          description:'',
          expiration_date: '',
          img: '',
          address:'',
          city:'',
          phone:'',
          email:''}
    }),
    mounted(){
      this.checkDevice();
    },
    beforeMount(){
        this.service = this.$route.params.service;
        var not = "";
        switch (this.service) {
            case 'locations':
                this.primaryColor = '#51C97B';
                this.secundaryColor = 'rgb(130 215 160)';
                try{
                    this.$http.get("app/locations/"+this.$route.params.service_id,{
                        headers:
                        this.getUserToken() != '' ?
                        {
                            "Authorization": "Bearer " + this.getUserToken(),
                            "X-Device-ID" : this.buildDeviceId(),
                            "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
                        } :
                        {
                          "X-Device-ID" : this.buildDeviceId(),
                          "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
                        }
                    }).then(function(response){
                        this.current_service.img = response.body.data.attributes.image.url;
                        this.current_service.title = response.body.data.attributes.title;
                        this.current_service.description = response.body.data.attributes.description;

                        this.current_service.address = response.body.data.attributes.address;
                        this.current_service.city = response.body.data.attributes.city;
                        this.current_service.phone = response.body.data.attributes.telephone;
                        this.current_service.email = response.body.data.attributes.manager_email;

                        if(response.body.meta != undefined && response.body.meta != null){
                          if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                            this.checkToken(response.body.meta.authentication_token)
                          }
                        }
                    }, function(response){
                      if(response.body.meta != undefined && response.body.meta != null){
                        if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                          this.checkToken(response.body.meta.authentication_token)
                        }
                      }
                      if (response.status==401) {
                        // this.show_error_modal(response.body.errors[0].details, "Un momento...");
                        this.show_error_modal("Tienes una sesión abierta en otro dispositivo.", "Un momento...");
                        this.deleteSession('login', true)
                      }else{
                        this.show_error_modal(response.body.errors[0].details);
                      }
                        // this.errors_handler(response);
                    })
                }catch(e){
                  alert(e.message);
                }
            break;
            case 'benefits':
                this.primaryColor = '#E33C76';
                this.secundaryColor = '#e9749c';
                try{
                  this.$http.get("app/offers/"+this.$route.params.service_id + not, {
                      headers:
                      this.getUserToken() != '' ?
                      {
                          "Authorization": "Bearer " + this.getUserToken(),
                          "X-Device-ID" : this.buildDeviceId(),
                          "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
                      } :
                      {
                        "X-Device-ID" : this.buildDeviceId(),
                        "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
                      }
                  }).then(function(response){
                    console.log(response);
                    this.current_service.title = response.body.data.attributes.title;
                    this.current_service.description = response.body.data.attributes.description;
                    this.current_service.expiration_date = response.body.data.attributes["expiration"];
                    this.current_service.img = response.body.data.attributes.images[0].image.url;
                      // this.offer.id = response.body.data.id;
                      // this.offer.attributes.tc = response.body.data.attributes.tc;
                      // this.offer.attributes["is_favorite"] = response.body.data.attributes["is_favorite"];
                      // this.offer.attributes.tags = response.body.data.attributes.tags;
                      // this.offer.attributes.published = response.body.data.attributes.published;
                      // this.offer.attributes.is_class = response.body.data.attributes.is_class;
                      // this.updateCurrentFavorites(response.body.data.attributes.favorites)
                      // this.title = response.body.data.attributes["category-name"];
                      // this.vouchers_count = response.body.data.attributes['vouchers-count'];
                      // this.is_favorite = response.body.meta.is_favorite
                      // this.charge_information = true;
                      if(response.body.meta != undefined && response.body.meta != null){
                        if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                          this.checkToken(response.body.meta.authentication_token)
                        }
                      }
                      // for (var i = 0; i < response.body.included.length; i++) {
                      //   if(response.body.included[i].type == 'locations'){
                      //     this.locations.push(response.body.included[i])
                      //   }
                      // }
                  }, function(response){
                    if(response.body.meta != undefined && response.body.meta != null){
                      if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                        this.checkToken(response.body.meta.authentication_token)
                      }
                    }
                    alert(response.body.errors[0].details);
                  })
              } catch (e) {
                alert(e.message);
              }
            break;
            case 'news':
                this.primaryColor = '#5242CB';
                this.secundaryColor = '#8479d8';
                try {
                    this.$http.get('app/articles/show',{
                /*  headers:
                    {"X-Device-ID" : this.getDeviceId(),
                    "Authorization" : 'Bearer ' + this.getUserToken()}, */
                    params:{
                        id: this.$route.params.service_id,
                    },
                    } )
                    .then(function(response){
                      console.log(response);
                    this.current_service.title = response.body.data.attributes.name;
                    this.current_service.description = response.body.data.attributes.description;
                    this.current_service.expiration_date = response.body.data.attributes.expiration;
                    this.current_service.img = response.body.data.attributes.url;
                    if(response.body.meta != undefined && response.body.meta != null){
                        if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                        this.checkToken(response.body.meta.authentication_token)
                        }
                    }
                    }, function(response){
                    if(response.body.meta != undefined && response.body.meta != null){
                        if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                        this.checkToken(response.body.meta.authentication_token)
                        }
                    }
                    })
                } catch (e) {
                    alert(e.message)
                }
            break;
            case 'events':
                this.primaryColor = '#17A277';
                this.secundaryColor = '#5bbc9e';
            break;
            case 'offers':
                this.primaryColor = '#F44336',
                this.secundaryColor = '#f57970'
                try{
                  this.$http.get("app/offers/"+this.$route.params.service_id + not, {
                      headers:
                      this.getUserToken() != '' ?
                      {
                          "Authorization": "Bearer " + this.getUserToken(),
                          "X-Device-ID" : this.buildDeviceId(),
                          "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
                      } :
                      {
                        "X-Device-ID" : this.buildDeviceId(),
                        "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
                      }
                  }).then(function(response){
                    console.log(response);
                    this.current_service.title = response.body.data.attributes.title;
                    this.current_service.description = response.body.data.attributes.description;
                    this.current_service.expiration_date = response.body.data.attributes["expiration"];
                    this.current_service.img = response.body.data.attributes.images[0].image.url;
                      // this.offer.id = response.body.data.id;
                      // this.offer.attributes.tc = response.body.data.attributes.tc;
                      // this.offer.attributes["is_favorite"] = response.body.data.attributes["is_favorite"];
                      // this.offer.attributes.tags = response.body.data.attributes.tags;
                      // this.offer.attributes.published = response.body.data.attributes.published;
                      // this.offer.attributes.is_class = response.body.data.attributes.is_class;
                      // this.updateCurrentFavorites(response.body.data.attributes.favorites)
                      // this.title = response.body.data.attributes["category-name"];
                      // this.vouchers_count = response.body.data.attributes['vouchers-count'];
                      // this.is_favorite = response.body.meta.is_favorite
                      // this.charge_information = true;
                      if(response.body.meta != undefined && response.body.meta != null){
                        if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                          this.checkToken(response.body.meta.authentication_token)
                        }
                      }
                      // for (var i = 0; i < response.body.included.length; i++) {
                      //   if(response.body.included[i].type == 'locations'){
                      //     this.locations.push(response.body.included[i])
                      //   }
                      // }
                  }, function(response){
                    if(response.body.meta != undefined && response.body.meta != null){
                      if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                        this.checkToken(response.body.meta.authentication_token)
                      }
                    }
                    alert(response.body.errors[0].details);
                  })
              } catch (e) {
                alert(e.message);
              }
            break;
            default:
                break;
        }
    }
    }
</script>
