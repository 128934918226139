<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20.004 20">
        <path d="M10,7.11a5.128,5.128,0,1,0,5.128,5.128A5.12,5.12,0,0,0,10,7.11Zm0,8.461a3.334,3.334,0,1,1,3.334-3.334A3.34,3.34,0,0,1,10,15.571ZM16.533,6.9a1.2,1.2,0,1,1-1.2-1.2A1.193,1.193,0,0,1,16.533,6.9Zm3.4,1.214a5.919,5.919,0,0,0-1.616-4.191,5.958,5.958,0,0,0-4.191-1.616c-1.651-.094-6.6-.094-8.252,0A5.949,5.949,0,0,0,1.681,3.919,5.938,5.938,0,0,0,.065,8.11c-.094,1.651-.094,6.6,0,8.252a5.919,5.919,0,0,0,1.616,4.191,5.965,5.965,0,0,0,4.191,1.616c1.651.094,6.6.094,8.252,0a5.919,5.919,0,0,0,4.191-1.616,5.958,5.958,0,0,0,1.616-4.191c.094-1.651.094-6.6,0-8.247ZM17.8,18.133a3.375,3.375,0,0,1-1.9,1.9c-1.317.522-4.44.4-5.9.4s-4.583.116-5.9-.4a3.375,3.375,0,0,1-1.9-1.9c-.522-1.317-.4-4.44-.4-5.9s-.116-4.583.4-5.9a3.375,3.375,0,0,1,1.9-1.9c1.317-.522,4.44-.4,5.9-.4s4.583-.116,5.9.4a3.375,3.375,0,0,1,1.9,1.9c.522,1.317.4,4.44.4,5.9S18.318,16.821,17.8,18.133Z" transform="translate(0.005 -2.238)" />
    </svg>
</template>
<script>
export default {
    props:{
        width: String,
        height: String
    }
}
</script>