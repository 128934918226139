<template>
  <v-app >
    <Navigation/>
    <v-main class="home">
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0">
        <router-view></router-view>
        <div
          class="content"
          transition="scroll-y-reverse-transition"
          :style="{borderTopColor:theme.primaryColor} " >
          <div class="content__header">
            <h1 class="content__header--title float-left" >
              Ofertas
            </h1>
          </div>
          <hr class="content__divider"/>
          <div v-if="charged">
            <v-row>
              <v-col
                lg="3"
                v-for="(category, index) in categories"
                :key="category.id"
                class="pr-0"
              >
                <a
                  href="javascript:;"
                  class="buttom_tabs buttom--block"
                  :class="{'buttom_tabs--active': active == index}"
                  @click="active = index; updateCurrentCategory(index)"
                  v-ripple="{ center: true }"
                  style="color:#808191"
                  :style="{'background-color': hoverTabs ? theme.primaryColor: '#FFFFFF'}"
                >
                  {{category.attributes.name}}
                </a>
              </v-col>
            </v-row>
            <div class="service row" v-if="categories.length > 0">
              <v-row>
                <div
                  class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2"
                  v-for="item in categories[active].offers"
                  :key="item.id">
                    <ServiceItem
                      :title="item.attributes.title"
                      :description="item.attributes.description"
                      :date="'Hasta el '+item.attributes['expiration']"
                      :img="item.attributes.images != undefined ? item.attributes.images[0].image.thumb.url : '//via.placeholder.com/300x200'"
                      :service_id="item.id.toString()"
                      :theme="theme"
                      button_text="oferta"
                    />
                </div>
              </v-row>
            </div>
          </div>

        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import Logo from '../assets/img/logo.png'

  export default {
    name: 'Locations',
    data () {
      return {
        url: "app/offers",
        charged: false,
        active: null,
        Logo: Logo,
        theme:{
          primaryColor: '#F44336',
          secundaryColor: '#f57970',
          service: 'offers'
        },
        hoverTabs: false,
        showCategory: true,
        months: ['Enero', 'Febrero', 'Marzo', 'Abril'],
        categories: [],
        photos:[]
      }
    },
    mounted(){
      this.charged = false
      this.send_request()
      this.checkDevice();
    },
    watch:{
      categories(){
        this.mergeCategoriesWithOffers()
      }
    },
    methods:{
      checkCategories(){
        for (var i = 0; i < this.categories.length; i++) {
          if(this.categories[i].offers.length <= 0){
            this.categories.splice(i, 1)
          }
        }
        console.log("Categories");
        console.log(this.categories);
      },
      send_request() {
        console.log("Envio de request");
        console.log("Datos de Geolocalización");
        console.log("lat: " + this.getLatitude().toString() + ", long: " + this.getLongitude().toString());
        try{
          // this.$http.get(this.getServer()+this.url+"?page="+vm.actual_page, {
          this.$http.get(this.url+"?page="+this.actual_page, {
            params:{
              check_app: true,
              company:{id:this.getCurrentCompany()}
            },
            headers:
            this.getUserToken() != '' ?
            {
                "Authorization": "Bearer " + this.getUserToken(),
                "X-Device-ID" : this.buildDeviceId(),
                "Geolocation" : "lat: " + this.getLatitude().toString() + ", long: " + this.getLongitude().toString()
            } :
            {
              "X-Device-ID" : this.buildDeviceId(),
              "Geolocation" : "lat: " + this.getLatitude().toString() + ", long: " + this.getLongitude().toString()
            }
          }).then(function(response){
            this.offers = response.body.data
            this.photos = response.body.included
            // this.mergeCategoriesWithOffers()
            // console.log(this.categories);
            console.log(this.offers);
            // if(response.body.meta.rewards_count != undefined && response.body.meta.rewards_count != null){
            //     this.updateRewardsCount(response.body.meta.rewards_count);
            // }
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
            this.findCategories();
          }, function(response){
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
            if (response.status==401) {
              // this.show_error_modal(response.body.errors[0].details, "Un momento...");
              this.show_error_modal("Tienes una sesión abierta en otro dispositivo.", "Un momento...");
              this.deleteSession('login', true)
            }else{
              this.show_error_modal(response.body.errors[0].details);
            }
            // this.errors_handler(response);
          });
        }catch(e){
          this.show_error_modal(e.message);
        }
      },
      findCategories(){
        // if(this.getCategories().length <= 0){
          try {
            this.$http.get('app/categories',{
              headers:
              this.getUserToken() != '' ?
              {
                  "Authorization": "Bearer " + this.getUserToken(),
                  "X-Device-ID" : this.buildDeviceId(),
                  "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
              } :
              {
                "X-Device-ID" : this.buildDeviceId(),
                "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
              },
              params:{
                company:{id:this.getCurrentCompany()}
              }
            })
            .then(function(response){
              console.log(response);
              this.categories = response.body.data
              console.log("--------------> Updated local categories");
              console.log(this.categories);
              this.updateCategories(response.body.data)
              console.log("--------------> Updated Categories");
              console.log(this.getCategories());
              console.log("--------------> Current category");
              console.log(this.getCurrentCategory());
              this.active = Number(this.getCurrentCategory())
              console.log(this.active);
              if(this.getCurrentCategory() != ''){
                // var vm = this
                // setTimeout(function(){
                //   vm.scrollToActive()
                //   console.log($('#category_id_'+ vm.active));
                // }, 500);
              }
              if(response.body.meta != undefined && response.body.meta != null){
                if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                  this.checkToken(response.body.meta.authentication_token)
                }
              }
              // this.mergeCategoriesWithOffers()
              this.charged = true
            }, function(response){
              if(response.body.meta != undefined && response.body.meta != null){
                if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                  this.checkToken(response.body.meta.authentication_token)
                }
              }
              console.log(response);
              alert(response)
            })
          } catch (e) {
            alert(e.message)
          }
        // }
      },
      mergeCategoriesWithOffers(){
        for (var i = 0; i < this.categories.length; i++) {
          this.$set(this.categories[i], 'offers', [])
          // this.categories[i].offers = []
          for (var j = 0; j < this.offers.length; j++) {
            if(this.offers[j].relationships.categories.data.length > 0 &&
               (this.offers[j].relationships.categories.data[0].id == this.categories[i].id)){
                this.categories[i].offers.push(this.offers[j])
            }
          }
        }
        console.log("----------> Categories");
        console.log(this.categories);
        this.checkCategories()
      },
    }
  }
</script>

<style scoped>
  .buttom_tabs--active{
    background: #F44336 0% 0% no-repeat padding-box !important;
    color: #FFF !important;
  }
  .buttom_tabs:hover{
    background: #F44336 0% 0% no-repeat padding-box !important;
    color: #FFF !important;
  }
</style>
