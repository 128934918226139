<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20.122 20">
        <path d="M20.684,10.623A10.061,10.061,0,1,0,9.051,20.562V13.532H6.5V10.623H9.051V8.407a3.55,3.55,0,0,1,3.8-3.914,15.483,15.483,0,0,1,2.252.2V7.164H13.835A1.454,1.454,0,0,0,12.2,8.735v1.888h2.79l-.446,2.908H12.2v7.031A10.064,10.064,0,0,0,20.684,10.623Z" transform="translate(-0.563 -0.563)" />
    </svg>
</template>
<script>
export default {
    props:{
        width: String,
        height: String
    }
}
</script>