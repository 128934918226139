<template>



<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="20" viewBox="0 0 15.556 20">
  <path id="cambiar-ctr" d="M22.333,9.111V5.556a5.556,5.556,0,1,0-11.111,0V9.111A3.237,3.237,0,0,0,9,12.222v4.444A3.274,3.274,0,0,0,12.333,20h8.889a3.274,3.274,0,0,0,3.333-3.333V12.222A3.237,3.237,0,0,0,22.333,9.111Zm-4.444,6.444a1.111,1.111,0,1,1-2.222,0V13.333a1.111,1.111,0,1,1,2.222,0Zm2.222-6.667H13.444V5.556a3.333,3.333,0,1,1,6.667,0Z" transform="translate(-9)" fill="#808191"/>
</svg>


</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
