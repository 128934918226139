import Vue from 'vue'
import App from './App.vue'
import router from './vue/router/index'
import store from './vue/store/index'
import './vue/mixins'
import './vue/resource/index'
import './vue/components'

import './assets/stylesheets/application.scss'
import vuetify from './plugins/vuetify'
import UUID from "vue-uuid";

Vue.use(UUID);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
