<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 19.5 25.5">
    <g transform="translate(-1598.25 -916.25)">
      <path 
        fill="none"
        :stroke="color"
        :stroke-width="stroke_width"
        class="a" 
        d="M12,0A9.043,9.043,0,0,0,3,9.065c0,7.1,8.154,14.437,8.5,14.745a.752.752,0,0,0,1,0C12.846,23.5,21,16.168,21,9.065A9.043,9.043,0,0,0,12,0Zm0,14a5,5,0,1,1,5-5A5.006,5.006,0,0,1,12,14Z" 
        transform="translate(1596 917)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props:{
    color: {
      default: '#808191',
      type: String
    },
    height: {
      default: '25.5',
      type: String
    },
    width: {
      default: '19.5',
      type: String
    },
    stroke_width: {
      default: '1.5',
      type: String
    }
  }
}
</script>

<style lang="css">
</style>
