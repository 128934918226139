<template>



<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
  <path id="icono-cerrar-sesion-all" d="M13.178,19.9l1.711,1.711L21,15.5,14.889,9.389,13.178,11.1l3.178,3.178H4.5v2.444H16.294ZM24.056,4.5H6.944A2.451,2.451,0,0,0,4.5,6.944v4.889H6.944V6.944H24.056V24.056H6.944V19.167H4.5v4.889A2.451,2.451,0,0,0,6.944,26.5H24.056A2.451,2.451,0,0,0,26.5,24.056V6.944A2.451,2.451,0,0,0,24.056,4.5Z" transform="translate(-4.5 -4.5)" fill="#808191"/>
</svg>


</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
