<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <path :fill="color" class="a" d="M28.375,3.375a25,25,0,1,0,25,25A25,25,0,0,0,28.375,3.375ZM38.447,28.844,21.957,38.82a.535.535,0,0,1-.805-.469V18.4a.533.533,0,0,1,.805-.469l16.49,9.976A.551.551,0,0,1,38.447,28.844Z" transform="translate(-3.375 -3.375)" />
    </svg>
</template>
<script>
export default {
    props: {
        color: {
            default: '#fff',
            type: String
        }
    }
}
</script>