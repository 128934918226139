<template>
<v-fade-transition>
    <div class="service_contain">
        <div class="service_contain__img" :style="{'backgroundImage': 'url('+img+')'}"></div>
        <!-- <img :src="img" class="service_contain__img" alt="Imagen" /> -->
        <div class="service_contain__body">
            <v-row class="py-0 mb-0 contain_info" v-if="category">
                <v-col class="col-sm-12 col-lg-12">
                    <a href="javascript:;" class="contain_info__category">
                        {{category}}
                    </a>
                </v-col>
            </v-row>
            <h5 class="service_contain__body--title" v-html="title"></h5>
            <p class="service_contain__body--description" v-html="description"></p>
            <p class="service_contain__body--date" :class="theme.service !== 'events' ? 'mb-3': 'mb-0'">
                <DateIcon
                    color="#1d8fcc"
                    class="service_contain__body--icon"
                    height="14.5"
                    width="11.5"
                />
                <span class="date__span ml-2">{{date}}</span>
            </p>
            <p class="service_contain__body--date mt-2" v-if="theme.service == 'events'">
                <LocationIcon
                    color="#1d8fcc"
                    class="service_contain__body--icon"
                    height="14.633"
                    width="11.3"
                />
                <span class="date__span ml-2">Ubicación del evento</span>
            </p>
            <RouterLink
                class="service_contain__body--button col-12"
                :to="'service/'+theme.service + '/' + service_id"
                :style="styleObject"
                v-ripple="{ center: true }"
            >
                Ver {{button_text}}
            </RouterLink>
        </div>
    </div>
</v-fade-transition>
</template>

<script>

export default {
    props: {
        theme: Object,
        title: String,
        description: String,
        date: String,
        category: String,
        button_text: String,
        img: String,
        service_id: String
    },
    data() {
        return {
            color: null
        }
    },
    components: {
    },
    computed: {
        styleObject: function() {
            return {
            '--color-hover': this.theme.primaryColor
            }
        }
    }
}
</script>
<style>
    .service_contain__body--button:hover {
        background: var(--color-hover) !important;
    }
</style>
