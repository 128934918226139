<template>
    <v-row
        class="fill-height py-8"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          {{ title }}
        </v-col>
        <v-col cols="4">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        title: String
    }
}
</script>