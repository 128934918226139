<template>

<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
  <g id="icono-editar-perfil" transform="translate(-2 -1.818)">
    <path id="Trazado_7380" data-name="Trazado 7380" d="M11.946,6H4.988A1.988,1.988,0,0,0,3,7.988V21.9a1.988,1.988,0,0,0,1.988,1.988H18.9A1.988,1.988,0,0,0,20.891,21.9V14.946" transform="translate(0 -1.073)" fill="none" stroke="#808191" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Trazado_7381" data-name="Trazado 7381" d="M22.437,3.436a2.109,2.109,0,0,1,2.982,2.982L15.976,15.86,12,16.854l.994-3.976Z" transform="translate(-3.036)" fill="none" stroke="#808191" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>


</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
