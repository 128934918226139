import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

let router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	let vm = router.app;
	if (to.name != "login") {
		try {
			if (
				vm.getUserToken() == undefined ||
				vm.getUserToken() == "" ||
				vm.getUserToken() == null
			) {
				vm.updateUserId("");
				next({ name: "login" });
			} else {
				next();
			}
		} catch (e) {
			next({ name: "login" });
		}
	} else {
		if (vm.getUserToken && vm.getUserToken()) {
			next({ name: "Offers" });
		} else {
			next();
		}
	}
});

export default router;
