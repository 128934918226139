const state = {
  current_company: null,
  user_token: '',
  latitude: '',
  longitude: '',
  device_id: '',
  user_id_encrypt: null,
  user_id: null,
  user_info_id: null,
  categories: [],
  current_category: null,
  locations:[],
  notifications: 0,
  badge_notifications: 0,
  is_mobile:false
}

const getters = {
  getCurrentCompany(state){
    return state.current_company
  },
  getUserToken(state){
    return state.user_token
  },
  getLatitude(state){
    return state.latitude
  },
  getLongitude(state){
    return state.longitude
  },
  getDeviceId(state){
    return state.device_id
  },
  getUserIdEncrypt(state){
    return state.user_id_encrypt
  },
  getUserId(state){
    return state.user_id
  },
  getUserInfoId(state){
    return state.user_info_id
  },
  getCategories(state){
    return state.categories
  },
  getCurrentCategory(state){
    return state.current_category
  },
  getLocations(state){
    return state.locations
  },
  getNotifications(state){
    return state.notifications
  },
  getBadgeNotifications(state){
    return state.badge_notifications
  },
  getIsMobile(state){
    return state.is_mobile
  },
}

const mutations = {
  setCurrentCompany(state, payload){
    state.current_company = payload;
  },
  setUserToken(state, payload){
    state.user_token = payload;
  },
  setLatitude(state, payload){
    state.latitude = payload;
  },
  setLongitude(state, payload){
    state.longitude = payload;
  },
  setDeviceId(state, payload){
    state.device_id = payload;
  },
  setUserIdEncrypt(state, payload){
    state.user_id_encrypt = payload
  },
  setUserId(state, payload){
    state.user_id = payload
  },
  setUserInfoId(state, payload){
    state.user_info_id = payload
  },
  setCategories(state, payload){
    state.categories = payload
  },
  setCurrentCategory(state, payload){
    state.current_category = payload
  },
  setLocations(state, payload){
    state.locations = payload
  },
  setNotifications(state, payload){
    state.notifications = payload
  },
  setBadgeNotifications(state, payload){
    state.badge_notifications = payload
  },
  setIsMobile(state, payload){
    state.is_mobile = payload
  },
}

const actions = {
  updateCurrentCompany({commit}, payload){
    commit("setCurrentCompany", payload);
  },
  updateUserToken({commit}, payload){
    commit("setUserToken", payload);
  },
  updateLatitude({commit}, payload){
    commit("setLatitude", payload);
  },
  updateLongitude({commit}, payload){
    commit("setLongitude", payload);
  },
  updateDeviceId({commit}, payload){
    commit("setDeviceId", payload);
  },
  updateUserIdEncrypt({commit}, payload) {
    commit('setUserIdEncrypt', payload)
  },
  updateUserId({commit}, payload) {
    commit('setUserId', payload)
  },
  updateUserInfoId({commit}, payload) {
    commit('setUserInfoId', payload)
  },
  updateCategories({commit}, payload) {
    commit('setCategories', payload)
  },
  updateCurrentCategory({commit}, payload) {
    commit('setCurrentCategory', payload)
  },
  updateLocations({commit}, payload) {
    commit('setLocations', payload)
  },
  updateNotifications({commit}, payload) {
    commit('setNotifications', payload)
  },
  updateBadgeNotifications({commit}, payload) {
    commit('setBadgeNotifications', payload)
  },
  updateIsMobile({commit}, payload) {
    commit('setIsMobile', payload)
  },
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
