<template>
    <div>
        <input 
            type="text" 
            :class="{'input_solid': solid, 'input_ligth': !solid}"
            :placeholder="placeholder"
        />
        <SearchIcon v-if="icon == 'true'" class="input_solid--icon" />
    </div>
</template>
<script>
export default {
    props:{
        solid: String,
        bg_color: String,
        placeholder: String,
        icon: String
    }
}
</script>