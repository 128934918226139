<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
  
  <path :fill="color" class="a" d="M16.375,3.375a13,13,0,1,0,13,13A13,13,0,0,0,16.375,3.375Zm3.294,17.706-3.294-3.294-3.294,3.294a1,1,0,1,1-1.413-1.412l3.294-3.294-3.294-3.294a1,1,0,0,1,1.413-1.412l3.294,3.294,3.294-3.294a1,1,0,1,1,1.412,1.412l-3.294,3.294,3.294,3.294a1,1,0,0,1,0,1.412A.992.992,0,0,1,19.669,21.081Z" transform="translate(-3.375 -3.375)" />
</svg>
</template>
<script>
export default {
  props:{
    color: {
      default: '#FFF',
      type: String
    }
  }
}
</script>