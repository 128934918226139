<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 32.061 32.065">
    <g
      transform="translate(-9.328 0.035)">
      <g transform="translate(18.507 8.779)">
        <g transform="translate(0)">
          <path
            :fill="color"
            d="M157.231,144.056a.9.9,0,0,0-1.275,0l-11.9,11.9a.9.9,0,1,0,1.275,1.275l11.9-11.9A.9.9,0,0,0,157.231,144.056Z"
            transform="translate(-143.791 -143.791)"/>
        </g>
      </g>
      <g transform="translate(18.447 7.516)">
        <path
          :fill="color"
          d="M146.108,123.116a3.306,3.306,0,1,0,3.306,3.306A3.31,3.31,0,0,0,146.108,123.116Zm0,4.809a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,146.108,127.925Z"
          transform="translate(-142.802 -123.116)"/>
      </g>
      <g transform="translate(25.659 17.133)">
        <g transform="translate(0)">
          <path
            :fill="color"
            d="M264.228,280.61a3.306,3.306,0,1,0,3.306,3.306A3.31,3.31,0,0,0,264.228,280.61Zm0,4.809a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,264.228,285.419Z"
            transform="translate(-260.922 -280.61)"/>
        </g>
      </g>
      <path
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2px"
        d="M28.632,15l1.316-3.433a.939.939,0,0,0-.285-1.065L26.809,8.19l-.577-3.632a.937.937,0,0,0-.78-.778L21.82,3.2,19.508.347A.939.939,0,0,0,18.445.062L15.01,1.38,11.577.063a.935.935,0,0,0-1.063.285L8.2,3.2l-3.632.577a.939.939,0,0,0-.778.778L3.213,8.191.358,10.505a.938.938,0,0,0-.285,1.063L1.389,15,.073,18.434A.935.935,0,0,0,.358,19.5l2.856,2.312.577,3.632a.937.937,0,0,0,.778.78L8.2,26.8l2.314,2.854a.933.933,0,0,0,1.065.285l3.431-1.314,3.433,1.316a.938.938,0,0,0,1.065-.287L21.82,26.8l3.632-.577a.939.939,0,0,0,.78-.78l.577-3.632L29.663,19.5a.936.936,0,0,0,.285-1.063Z"
        transform="translate(10.349 0.999)"/>
    </g>
  </svg>
</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
