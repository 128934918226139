<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 30 30">
    <g transform="translate(-0.057)">
      <g transform="translate(5.858)">
        <path
          :fill="color"
          d="M116.8,1.7a5.008,5.008,0,0,0-7.943.558c-.079.111-.153.221-.223.331-.069-.11-.143-.22-.223-.331a5.008,5.008,0,0,0-7.943-.558,5.858,5.858,0,0,0-1.435,3.913,7.081,7.081,0,0,0,1.969,4.683,42.514,42.514,0,0,0,4.8,4.443c.72.607,1.464,1.235,2.254,1.92a.879.879,0,0,0,1.151,0c.791-.685,1.535-1.313,2.254-1.92,1.25-1.055,2.33-1.967,3.268-2.851,1.82-1.716,3.5-3.664,3.5-6.275A5.857,5.857,0,0,0,116.8,1.7Zm-3.275,8.909c-.9.851-1.965,1.747-3.2,2.786-.547.462-1.109.936-1.7,1.439-.587-.5-1.149-.977-1.7-1.439-3.833-3.234-6.146-5.187-6.146-7.782a3.6,3.6,0,0,1,3.482-3.854,3.234,3.234,0,0,1,2.681,1.483,5.914,5.914,0,0,1,.833,1.651.879.879,0,0,0,1.694,0c.009-.031.9-3.134,3.514-3.134a3.6,3.6,0,0,1,3.482,3.854C116.479,7.2,115.651,8.6,113.527,10.608Z"
          transform="translate(-99.036)"/>
      </g>
      <g transform="translate(0.057 17.345)">
        <g transform="translate(0)">
          <path
            :fill="color"
            d="M27.42,296.374a2.646,2.646,0,0,0-1.93.84l-2.583,2.773a2.641,2.641,0,0,0-2.518-1.855h-4.06a2.335,2.335,0,0,1-1.543-.582,6.166,6.166,0,0,0-8.062-.056,2.635,2.635,0,0,0-2.155-1.119H.936a.879.879,0,0,0-.879.879V307.8a.879.879,0,0,0,.879.879H4.569a2.631,2.631,0,0,0,1.982-.9l.52.146a20.219,20.219,0,0,0,5.47.754h7.848a6.182,6.182,0,0,0,4.922-2.46l4.22-5.625h0a2.636,2.636,0,0,0-2.11-4.216ZM5.448,306.04a.88.88,0,0,1-.879.879H1.815v-8.787H4.569a.88.88,0,0,1,.879.879Zm22.677-6.5h0l-4.22,5.625a4.416,4.416,0,0,1-3.516,1.757H12.541a18.461,18.461,0,0,1-4.994-.688l-.344-.1c0-.031,0-.062,0-.094v-6.633l.612-.539a4.4,4.4,0,0,1,5.806,0,4.092,4.092,0,0,0,2.7,1.02h4.06a.879.879,0,1,1,0,1.757H14.143a.879.879,0,1,0,0,1.757h6.836a2.646,2.646,0,0,0,1.929-.839l3.869-4.153a.871.871,0,0,1,.643-.28.88.88,0,0,1,.879.879A.87.87,0,0,1,28.124,299.536Z"
            transform="translate(-0.057 -296.021)"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props:{
  color: {
    default: '#808191',
    type: String
  }
}
}
</script>

<style lang="css">
</style>
