<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9">
        <path id="icono-flecha-cat" d="M13.964,13.692,19.92,8.021a1.032,1.032,0,0,0,0-1.514,1.17,1.17,0,0,0-1.593,0l-6.749,6.426a1.034,1.034,0,0,0-.033,1.478l6.777,6.47a1.172,1.172,0,0,0,1.593,0,1.032,1.032,0,0,0,0-1.514Z" transform="translate(21.194 -11.251) rotate(90)" :fill="color"/>
    </svg>
</template>
<script>
export default {
  props:{
    color: {
      default: '#1B1D21',
      type: String
    }
  }
}
</script>