<template>
    <div class="service_contain">
        <div class="service_contain__video">
          <iframe
            class="service_contain__cover"
            :src="'https://www.youtube.com/embed/' + url.replace('https://youtu.be/','')+'?rel=0'"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
            <!-- <div class="" @click.stop="dialog = true">
                <PlayIcon />
            </div> -->
        </div>
        <div class="service_contain__body">
            <v-row class="py-2 contain_info">
                <v-col class="col-sm-12 col-lg-6 col-sm-12">
                    <a href="javascript:;" class="contain_info__category">
                        {{category}}
                    </a>
                </v-col>
                <v-col class="text-right col-sm-12 col-lg-6 col-sm-12">
                    <a href="javascript:;" class="contain_info__date">
                        <v-icon class="contain_info__date--icon">date_range</v-icon>
                        {{date}}
                    </a>
                </v-col>
            </v-row>
            <h5 class="service_contain__body--title mt-2" v-html="title">
            </h5>
        </div>
        <v-dialog
            v-model="dialog"
            width="600px"
        >
        <v-card class="dialog_new__detail">
            <v-card-title
                class="dialog_new__detail--head mb-4 py-1 pt-3"
                :style="{backgroundColor: theme.primaryColor}"
            >
                <a
                  href="javascript:;"
                  @click.stop="dialog = false"
                >
                  <CloseIcon/>
                </a>
            </v-card-title>
            <v-card-text>
                <p class="dialog_new__detail--title">Título del video título del video título del video título del video con 2 renglones máximo</p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum
            </v-card-text>
            <v-card-text class="dialog_new__detail--date">
                23 de septiembre de 2021
            </v-card-text>
        </v-card>
        </v-dialog>

    </div>
</template>
<script>
export default {
    props: {
        theme: Object,
        title: String,
        description: String,
        date: String,
        category: String,
        url: String
    },
    data() {
        return {
            color: null,
            dialog: false,
        }
    },
    components: {
    },
    computed: {
        styleObject: function() {
            return {
            '--color-hover': this.theme.primaryColor
            }
        }
    }
}
</script>
<style>
    .service_contain__body--button:hover {
        background: var(--color-hover) !important;
    }
</style>
