<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
        <path d="M17,5.156a9.914,9.914,0,0,0-15.6,11.96L0,22.25l5.254-1.379a9.881,9.881,0,0,0,4.737,1.205h0a10.006,10.006,0,0,0,10-9.911,9.95,9.95,0,0,0-3-7.009ZM10,20.406a8.223,8.223,0,0,1-4.2-1.147l-.3-.179L2.384,19.9l.83-3.04-.2-.312a8.253,8.253,0,1,1,15.308-4.379A8.329,8.329,0,0,1,10,20.406Zm4.518-6.17c-.246-.125-1.464-.723-1.692-.8s-.393-.125-.558.125-.638.8-.786.973-.29.188-.536.063a6.741,6.741,0,0,1-3.371-2.946c-.254-.437.254-.406.728-1.353a.459.459,0,0,0-.022-.433c-.062-.125-.558-1.344-.763-1.839s-.406-.415-.558-.424-.308-.009-.473-.009a.917.917,0,0,0-.661.308,2.783,2.783,0,0,0-.866,2.067,4.851,4.851,0,0,0,1.009,2.562A11.066,11.066,0,0,0,10.2,16.268a4.846,4.846,0,0,0,2.973.621,2.536,2.536,0,0,0,1.67-1.179,2.071,2.071,0,0,0,.143-1.179C14.924,14.42,14.759,14.357,14.513,14.237Z" transform="translate(0 -2.25)" />
    </svg>
</template>
<script>
export default {
    props:{
        width: String,
        height: String,
    }
}
</script>