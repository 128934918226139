import Home from './../../views/Home.vue'
import OfferDetail from './../../views/OfferDetail.vue'

export default [
  {
    path: '/offers',
    name: 'Offers',
    component: Home
  },
  {
    path: '/service/:service/:service_id',
    name: 'OfferDetail',
    component: OfferDetail
  },
  {
    path: '/benefits',
    name: 'benefits',
    component: () => import('./../../views/Benefits.vue')
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import('./../../views/Locations.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('./../../views/News.vue')
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('./../../views/Events.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('./../../views/Notifications.vue')
  },
  {
    path: '/companys',
    name: 'companys',
    component: () => import('./../../views/Company.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('./../../views/Login.vue')
  }

]
