<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="icono-agregar-foto" transform="translate(-150 -50)">
            <g id="Elipse_57" data-name="Elipse 57" transform="translate(150 50)" fill="#fff" stroke="#808191" stroke-width="1">
            <circle cx="20" cy="20" r="20" stroke="none"/>
            <circle cx="20" cy="20" r="19.5" fill="none"/>
            </g>
            <g id="icono-camara" transform="translate(157.5 56.5)">
            <path id="Trazado_7378" data-name="Trazado 7378" d="M23.5,20.5a2,2,0,0,1-2,2H3.5a2,2,0,0,1-2-2V9.5a2,2,0,0,1,2-2h4l2-3h6l2,3h4a2,2,0,0,1,2,2Z" transform="translate(0 0)" fill="none" stroke="#808191" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Trazado_7379" data-name="Trazado 7379" d="M20,17.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z" transform="translate(-3.5 -3)" fill="none" stroke="#808191" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
