<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
// import Navigation from './components/shared/Navigation.vue'
export default {
  // components: { Navigation },
  data: () => ({
    Nav: 'Aplication'
  }),
  created(){
    var vm = this;
    navigator.geolocation.getCurrentPosition(vm.findPosition)
  },
  mounted(){
    this.updateCurrentCompany(6)
  }
}
</script>
