import Vue from 'vue'


Vue.component('Navigation', require('./../components/shared/Navigation.vue').default)

//General Icons
Vue.component('OffersIcon', require('./../components/shared/icons/OffersIcon.vue').default)
Vue.component('LocationIcon', require('./../components/shared/icons/LocationIcon.vue').default)
Vue.component('NewsIcon', require('./../components/shared/icons/NewsIcon.vue').default)
Vue.component('EventsIcon', require('./../components/shared/icons/EventsIcon.vue').default)
Vue.component('MessageIcon', require('./../components/shared/icons/MessageIcon.vue').default)
Vue.component('TramitesInternosIcon', require('./../components/shared/icons/InsideRequestIcon.vue').default)
Vue.component('ClientConnectionsIcon', require('./../components/shared/icons/ClientConnectionsIcon.vue').default)
Vue.component('RewardsIcon', require('./../components/shared/icons/RewardsIcon.vue').default)
Vue.component('PlayIcon', require('./../components/shared/icons/Play.vue').default)
Vue.component('DateIcon', require('./../components/shared/icons/DateIcon.vue').default)
Vue.component('CloseIcon', require('./../components/shared/icons/CloseIcon.vue').default)
Vue.component('SearchIcon', require('./../components/shared/icons/SearchIcon.vue').default)
Vue.component('ExpandIcon', require('./../components/shared/icons/ExpandIcon.vue').default)
Vue.component('ArrowIcon', require('./../components/shared/icons/ArrowIcon.vue').default)
Vue.component('PinIcon', require('./../components/shared/icons/PinIcon.vue').default)
Vue.component('TermsIcon', require('./../components/shared/icons/TermsIcon.vue').default)
Vue.component('EditProfileIcon', require('./../components/shared/icons/EditProfileIcon.vue').default)
Vue.component('LogoutIcon', require('./../components/shared/icons/LogoutIcon.vue').default)
Vue.component('PasswordIcon', require('./../components/shared/icons/PasswordIcon.vue').default)
Vue.component('LogoutAllIcon', require('./../components/shared/icons/LogoutAllIcon.vue').default)
Vue.component('CameraIcon', require('./../components/shared/icons/CameraIcon.vue').default)

//Social Icons
Vue.component('GlobeIcon', require('./../components/shared/icons/social/Globe.vue').default)
Vue.component('FacebookIcon', require('./../components/shared/icons/social/Facebook.vue').default)
Vue.component('InstagramIcon', require('./../components/shared/icons/social/Instagram.vue').default)
Vue.component('WhatsAppIcon', require('./../components/shared/icons/social/WhatsApp.vue').default)

Vue.component('ProgressLinear', require('./../components/shared/Progress.vue').default)

Vue.component('ServiceItem', require('./../components/ServiceItem.vue').default)
Vue.component('VideoServiceItem', require('./../components/VideoServiceItem.vue').default)
Vue.component('ServiceItemHorizontal', require('./../components/ServiceItemHorizontal.vue').default)
Vue.component('InputIcon', require('./../components/shared/InputIcon.vue').default)

