<template>


<svg xmlns="http://www.w3.org/2000/svg" width="19.997" height="22" viewBox="0 0 19.997 22">
  <g id="icono-cerrar-sesion" transform="translate(-3.493 -2)">
    <path id="Trazado_7375" data-name="Trazado 7375" d="M19.855,9.96a9,9,0,1,1-12.728,0" transform="translate(0 -2.321)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Trazado_7376" data-name="Trazado 7376" d="M18,3V13" transform="translate(-4.504)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>

</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
