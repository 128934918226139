<template>
    <div class="horizontal_service">
        <v-row>
            <div class="col-5">
                <div class="horizontal_service__img" :style="{'backgroundImage': 'url('+img+')'}"></div>
            </div>
            <div class="col-7 horizontal_service__body pl-0 py-4">
                <RouterLink
                    :to="'service/'+theme.service+'/'+service_id"
                    :style="styleObject"
                >
                    <h5 class="horizontal_service__body--title" v-html="title"></h5>
                </RouterLink>
                <p class="horizontal_service__body--description" v-html="description"></p>
            </div>
        </v-row>
    </div>
</template>
<script>

export default {
    props: {
        theme: Object,
        title: String,
        description: String,
        date: String,
        category: String,
        button_text: String,
        img: String,
        service_id: String
    },
    data() {
        return {
            color: null,
        }
    },
    components: {
    },
    computed: {
        styleObject: function() {
            return {
            '--color-hover': this.theme.primaryColor
            }
        }
    }
}
</script>
<style>
    .service_contain__body--button:hover {
        background: var(--color-hover) !important;
    }
</style>
