<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 17 18.308">
        <path :fill="color" class="a" d="M10.167,11.238H8.278v1.831h1.889Zm3.778,0H12.056v1.831h1.889Zm3.778,0H15.833v1.831h1.889Zm1.889-6.408h-.944V3H16.778V4.831H9.222V3H7.333V4.831H6.389A1.852,1.852,0,0,0,4.509,6.662L4.5,19.477a1.86,1.86,0,0,0,1.889,1.831H19.611A1.866,1.866,0,0,0,21.5,19.477V6.662A1.866,1.866,0,0,0,19.611,4.831Zm0,14.646H6.389V9.408H19.611Z" transform="translate(-4.5 -3)" />
    </svg>
</template>
<script>
export default {
    props:{
    color: {
      default: '#1D8FCC',
      type: String
    },
    height: {
      default: '18.308',
      type: String
    },
    width: {
      default: '17',
      type: String
    },
    stroke_width: {
      default: '1.5',
      type: String
    }
  }
}
</script>