<template>
<svg xmlns="http://www.w3.org/2000/svg" width="18.463" height="24" viewBox="0 0 18.463 24">
  <path id="icono-terminos" d="M23.99,7.372A1.208,1.208,0,0,0,22.773,8.56v6.31a.513.513,0,0,1-1.027,0V5.561a1.217,1.217,0,0,0-2.434,0v7.314a.513.513,0,0,1-1.027,0V4.563a1.217,1.217,0,0,0-2.434,0v9.309a.513.513,0,0,1-1.027,0V6.559a1.217,1.217,0,0,0-2.434,0V19.15l-1.6-1.361c-1.505-1.309-2.78-1.869-3.818-.906-.7.681.4,1.782,1.546,3.068,1.107,1.24,4.037,5.3,6.535,6.766a4.5,4.5,0,0,0,2.342.658h3.3c2.659,0,4.522-2.186,4.522-5.376V8.56A1.217,1.217,0,0,0,23.99,7.372Z" transform="translate(-6.75 -3.375)" fill="#808191"/>
</svg>
</template>

<script>
export default {

props:{
  color: {
    default: '#808191',
    type: String
  }
}

}
</script>

<style lang="css">
</style>
