<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="25" 
    viewBox="0 0 26 34">
    <g transform="translate(-61)">
      <path
        :fill="color"
        d="M86.707,8.26l-8-7.969A1,1,0,0,0,78,0H64a3,3,0,0,0-3,2.988V31.012A3,3,0,0,0,64,34H84a3,3,0,0,0,3-2.988V8.965A1,1,0,0,0,86.707,8.26ZM79,3.4l4.586,4.568H80a1,1,0,0,1-1-1Zm5,28.607H64a1,1,0,0,1-1-1V2.988a1,1,0,0,1,1-1H77v4.98a3,3,0,0,0,3,2.988h5V31.012A1,1,0,0,1,84,32.008Z"
        transform="translate(0)"/>
      <path
        :fill="color"
        d="M164,212H152a1,1,0,1,0,0,1.992h12A1,1,0,1,0,164,212Z"
        transform="translate(-84 -197.922)"/>
      <path
        :fill="color"
        d="M164,272H152a1,1,0,1,0,0,1.992h12A1,1,0,1,0,164,272Z"
        transform="translate(-84 -253.938)"/>
      <path
        :fill="color"
        d="M164,332H152a1,1,0,1,0,0,1.992h12A1,1,0,1,0,164,332Z"
        transform="translate(-84 -309.953)"/>
      <path
        :fill="color"
        d="M160,392h-8a1,1,0,1,0,0,1.992h8A1,1,0,1,0,160,392Z"
        transform="translate(-84 -365.969)"/>
    </g>
  </svg>
</template>

<script>
export default {
  props:{
    color: {
      default: '#808191',
      type: String
    }
  }
}
</script>

<style lang="css">
</style>
