<template>
    <div
                class="detail_offer__header" 
                :style="{backgroundColor: primaryColor}"
            >
                <v-row>
                    <a 
                        class="detail_offer__btn--link" 
                        @click="$router.go(-1)"
                    >
                        <v-icon class="detail_offer__btn--icon">arrow_back</v-icon>
                        <span class="detail_offer__btn--text">Atrás</span>
                    </a>
                    
                    <v-row 
                        justify="end" 
                        align-content="center" 
                        class="mr-lg-3pr-md-3 mr-sm-3 my-1"
                    >
                        <a 
                            href="#"
                            class="detail_offer__btn__icon mr-3 px-6"
                            v-ripple="{ center: true }"
                            v-if="service === 'news'"
                            :style="{backgroundColor: secundaryColor, border:secundaryColor }"
                        >
                            <span class="detail_offer__btn__icon--text">CATEGORÍA</span>
                        </a>
                        <a 
                            href="#"
                            class="detail_offer__btn__icon mr-3"
                            v-ripple="{ center: true }"
                            :style="{backgroundColor: secundaryColor, border:secundaryColor }"
                        >
                            <img :src="icons.share">
                        </a>
                        <a 
                            href="#"
                            class="detail_offer__btn__icon mr-3"
                            v-ripple="{ center: true }"
                            :style="{backgroundColor: secundaryColor, border:secundaryColor }"
                        >
                            <img :src="icons.favorite">
                        </a>
                        <button 
                            class="detail_offer__btn px-sm-8 px-8"
                            :style="{color: primaryColor}"
                            style="background-color: #FFFFFF"
                            v-ripple="{ center: true }"
                            v-if="service === 'offers'"
                        >
                            <span class="px-lg-2">Canjear oferta</span> 
                        </button>
                        <button 
                            class="detail_offer__btn px-sm-8 px-8"
                            :style="{color: primaryColor}"
                            style="background-color: #FFFFFF"
                            v-ripple="{ center: true }"
                            v-if="service === 'events'"
                        >
                            <span class="px-lg-2">Asistir al evento</span> 
                        </button>
                    </v-row>
                </v-row>
            </div>
</template>
<script>
export default {
    props:{
        primaryColor: String,
        secundaryColor: String,
        service: String,
        icons: Object
    }
}
</script>