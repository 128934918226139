<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M0,8.857V3.321A1.069,1.069,0,0,1,1.071,2.25H6.607a.537.537,0,0,1,.536.536V4.571a.537.537,0,0,1-.536.536H2.857v3.75a.537.537,0,0,1-.536.536H.536A.537.537,0,0,1,0,8.857ZM12.857,2.786V4.571a.537.537,0,0,0,.536.536h3.75v3.75a.537.537,0,0,0,.536.536h1.786A.537.537,0,0,0,20,8.857V3.321A1.069,1.069,0,0,0,18.929,2.25H13.393A.537.537,0,0,0,12.857,2.786Zm6.607,12.321H17.679a.537.537,0,0,0-.536.536v3.75h-3.75a.537.537,0,0,0-.536.536v1.786a.537.537,0,0,0,.536.536h5.536A1.069,1.069,0,0,0,20,21.179V15.643A.537.537,0,0,0,19.464,15.107ZM7.143,21.714V19.929a.537.537,0,0,0-.536-.536H2.857v-3.75a.537.537,0,0,0-.536-.536H.536A.537.537,0,0,0,0,15.643v5.536A1.069,1.069,0,0,0,1.071,22.25H6.607A.537.537,0,0,0,7.143,21.714Z" transform="translate(0 -2.25)" />
    </svg>
</template>
<script>
export default {
    props:{
        width: String,
        height: String
    }
}
</script>